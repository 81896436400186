import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import FormWrapper from '../common/FormWrapper';
import { ExperienceListItem } from '../../interfaces';
import { useExperiencesStore } from '../../store/experiences';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { useUserStore } from '../../store/user';
import configureAxios from '../../axiosClient';
import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';

type RenameFormValues = {
  experienceName: string;
};
interface RenameExperienceModalProps {
  renameModalActive: boolean;
  setRenameModalActive: (state: boolean) => void;
  renameExperience: (updatedExperience: ExperienceListItem) => void;
}

const axiosInstance = configureAxios();

function RenameExperienceModal({
  renameModalActive,
  setRenameModalActive,
  renameExperience,
}: RenameExperienceModalProps) {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const organisation = useUserStore((state) => state.organisation);
  const selectedExperience = useExperiencesStore(
    (state) => state.selectedExperience
  );

  useEffect(() => {
    reset({
      experienceName: selectedExperience.name,
    });
  }, [selectedExperience]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<RenameFormValues>({
    defaultValues: useMemo(() => {
      return {
        experienceName: selectedExperience.name,
      };
    }, [selectedExperience]),
  });

  const onSubmit: SubmitHandler<RenameFormValues> = (data) => {
    const userOrgId = organisation.organizationId;

    const updatedExpItem: ExperienceListItem = {
      id: selectedExperience.id,
      name: data.experienceName,
      description: selectedExperience.id,
      created: selectedExperience.created,
      modified: selectedExperience.modified,
      processed: selectedExperience.processed,
      preview_url: selectedExperience.preview_url,
      published_url: selectedExperience.published_url,
      community_url: selectedExperience.community_url,
      like_count: selectedExperience.like_count,
      tags: selectedExperience.tags,
      clone_count: selectedExperience.clone_count,
      is_community_shared: selectedExperience.is_community_shared,
      community_shared_at: selectedExperience.community_shared_at,
    };

    axiosInstance
      .post(`experience/update/name/${userOrgId}/${selectedExperience.id}`, {
        name: data.experienceName,
      })
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';

        if (isStatusOk) {
          setValue('experienceName', '');
          renameExperience(updatedExpItem);
          setRenameModalActive(false);
        } else {
          toast({
            title: `There was an error renaming experience`,
            status: 'error',
          });
        }
      })
      .catch((err: unknown) => {
        console.log(err);
      });
  };

  return (
    <Modal
      isOpen={renameModalActive}
      onClose={() => setRenameModalActive(false)}
      isCentered
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        minW={['500px']}
        height={['auto']}
        margin='auto'
        maxHeight='80vh'
        overflow='auto'
        padding={['20px']}
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={() => setRenameModalActive(false)}
        />
        <ModalBody
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          gap={5}
        >
          <Text
            color='#08101D'
            fontFamily='Metal'
            fontSize={['35px']}
            lineHeight={['35px']}
            textAlign='center'
            textTransform='uppercase'
          >
            Update Name
          </Text>

          <FormWrapper
            onSubmit={handleSubmit(onSubmit)}
            name='rename_experience'
          >
            <Box
              w={['100%']}
              height={['auto']}
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              gap={5}
            >
              <FormControl
                isInvalid={errors.experienceName ? true : false}
                isRequired
              >
                <FormLabel
                  color='rgba(127, 139, 158, 1)'
                  fontFamily='Normal'
                  fontSize='12px'
                  margin='0px'
                >
                  Experience Name
                </FormLabel>
                <Input
                  id='experienceName'
                  placeholder='enter experience name'
                  color='#58677E'
                  borderBottom='1px solid #D6DBE4'
                  variant='flushed'
                  fontFamily='Normal'
                  fontSize={['14px', '14px', '15px']}
                  size='md'
                  _placeholder={{ color: '#7F8B9E' }}
                  errorBorderColor='#EA1A4C'
                  {...register('experienceName', {
                    required: 'This is required',
                    minLength: {
                      value: 4,
                      message: 'Minimum length should be 4',
                    },
                    maxLength: {
                      value: 20,
                      message: 'Maximum length should be 20',
                    },
                    pattern: {
                      value: /^[a-zA-Z][^()[\]{}!@#$%^&*+=|\\;:'",.<>?~`]*$/,
                      message:
                        'Must start with alphabet and no white spaces, special characters, or brackets allowed',
                    },
                  })}
                />
                <FormErrorMessage
                  color='#EA1A4C'
                  mt={['4px']}
                  fontSize='13px'
                  fontFamily='Normal'
                >
                  {errors.experienceName && errors.experienceName.message}
                </FormErrorMessage>
              </FormControl>

              <Button
                mt='20px'
                textTransform='uppercase'
                width={['80%', '80%', '300px']}
                variant='outline'
                color='#08101D'
                background='white'
                fontFamily='Metal'
                fontSize={['14px', '14px', '14px']}
                borderRadius='30px'
                border='1px solid #D6DBE4'
                _hover={{
                  bg: '#D3BB8A',
                  borderColor: '#D3BB8A',
                }}
                isLoading={isSubmitting}
                type='submit'
              >
                Update
              </Button>
            </Box>
          </FormWrapper>

          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
          >
            <Button
              fontSize={['11px']}
              variant='link'
              fontFamily='Normal'
              textAlign='center'
              mt={['15px']}
              onClick={() => setRenameModalActive(false)}
            >
              Cancel
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default RenameExperienceModal;
